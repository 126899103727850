@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/admin-lte/dist/css/AdminLTE.css';
@import '../node_modules/admin-lte/dist/css/skins/skin-blue.css';

@import '../node_modules/react-grid-layout/css/styles.css';
@import '../node_modules/react-resizable/css/styles.css';

.inline-div{
  display: inline-block;
}

.inline-label{
  float:left;
  margin-top: 8px;
}

.hover-button {
  height: 40px;
  width: 250px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.button-hide {
  display: none;
  margin-left: 3px;
  margin-right: 3px
}

.hover-button:hover button {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px
}

section.content {
  min-height: 100vh;
}

.metro-buttons {
  .metro-button {
    min-height: 60px;
    padding-left: 20px;
    margin-top: 1em;
    font-size: 14px;
    align-items: flex-start;
  }

  a.metro-button {
    padding-top: 15px;
  }
}


.main-header .sidebar-toggle:before {
  font-family: FontAwesome, serif;
  content: "" !important;
}

.label-explain {
  color: lightgray;

  &:hover {
    color: black;
  }
}

.price-stats {
  margin-right: 1em;
}

.online-indicator {
  width: 2px;
  height: 1.1em;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 2px;

  &.online {
    background-color: green;
  }

  &.offline {
    background-color: gray;
  }
}

.flash-message {
  padding: 20px 30px;
  background: rgb(243, 156, 18);
  z-index: 999999;
  font-size: 16px;
  font-weight: 600;
}

.hover-cursor-pointer:hover {
  cursor: pointer;
}

.steps {
  .step {
    i {
      margin-right: 5px;
    }
  }
}

li.treeview {
  .treeview-menu {
    display: none;
  }

  &.menu-open {
    .treeview-menu {
      display: block;
    }
  }
}


.react-grid-item {
  border: 1px solid black;
}

.planogram {
  .planogram-item {
    .item-name {
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.quantity-warning {
  font-weight: bolder;
}

ul.prekitting-actions {
  padding: 0;

  li {
    list-style: none;
    display: inline;
    margin-right: 2em;
  }

}

.text-striked {
  text-decoration: line-through;
}

.stop {
  padding-bottom: 0.5em;

  label {
    margin-bottom: 0;
  }

  .address {
    color: #878;
  }
}


.drop-down-vending-machine-detail {
  font-size: 0.8em;
  padding-left: 1em;
}

#listBarcodes.table-bordered{
  border:1px solid black;
  margin-top:20px;
}
#listBarcodes.table-bordered > thead > tr > th{
  border:1px solid black;
}
#listBarcodes.table-bordered > tbody > tr > td{
  border:1px solid black;
}



.customhr {
  border-top: 1px dashed #8c8b8b;
}

.page-link{
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.keypadButton{
  border: 1px solid black;
  font-size: 3em;
  height: 1.3em;
  width: 1.3em;
}

#iOSBackground{
  background-image: url("./assets/long_ios.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  width: 405px;
  height: 329px;
}

.messageBox{
  width: 360px;
  height: 75px;
  background: aliceblue;
  border-radius: 8px;
  margin: 16% 8% 0;
}

.messageBody{
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0;
}

.messageTitle{
  font-weight: bold;
  margin: 0;
}

.messageImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  width:60px;
  height:60px;
  margin-top: 7px;
}

.refundedTrans{
  text-decoration: line-through;
}

.statsBox{
  min-height: 55vh;
}

.tube-stats-box-icon {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  height: 90px;
  width: auto;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0, 0, 0, 0.2);
  padding: 0 5px 0 5px
}


.sign-in-and-up-form-control {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px !important;
  text-align: center;
  pointer-events: none;
}

.connection-just-restarted {
  font-size: smaller;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}


/*
*   CSS file with Bootstrap grid classes for screens bigger than 1600px. Just add this file after the Bootstrap CSS file and you will be able to juse col-xl, col-xl-push, hidden-xl, etc.
*
*   Author: Marc van Nieuwenhuijzen
*   Company: WebVakman
*   Site: WebVakman.nl
*
*/

@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none !important;
  }
}


.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl{
  display: none !important;
}



@media (min-width: 1800px) {
  .container {
    width: 1770px;
  }

  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.66666667%;
  }

  .col-xl-10 {
    width: 83.33333333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.66666667%;
  }

  .col-xl-7 {
    width: 58.33333333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.66666667%;
  }

  .col-xl-4 {
    width: 33.33333333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.66666667%;
  }

  .col-xl-1 {
    width: 8.33333333%;
  }

  .col-xl-pull-12 {
    right: 100%;
  }

  .col-xl-pull-11 {
    right: 91.66666667%;
  }

  .col-xl-pull-10 {
    right: 83.33333333%;
  }

  .col-xl-pull-9 {
    right: 75%;
  }

  .col-xl-pull-8 {
    right: 66.66666667%;
  }

  .col-xl-pull-7 {
    right: 58.33333333%;
  }

  .col-xl-pull-6 {
    right: 50%;
  }

  .col-xl-pull-5 {
    right: 41.66666667%;
  }

  .col-xl-pull-4 {
    right: 33.33333333%;
  }

  .col-xl-pull-3 {
    right: 25%;
  }

  .col-xl-pull-2 {
    right: 16.66666667%;
  }

  .col-xl-pull-1 {
    right: 8.33333333%;
  }

  .col-xl-pull-0 {
    right: auto;
  }

  .col-xl-push-12 {
    left: 100%;
  }

  .col-xl-push-11 {
    left: 91.66666667%;
  }

  .col-xl-push-10 {
    left: 83.33333333%;
  }

  .col-xl-push-9 {
    left: 75%;
  }

  .col-xl-push-8 {
    left: 66.66666667%;
  }

  .col-xl-push-7 {
    left: 58.33333333%;
  }

  .col-xl-push-6 {
    left: 50%;
  }

  .col-xl-push-5 {
    left: 41.66666667%;
  }

  .col-xl-push-4 {
    left: 33.33333333%;
  }

  .col-xl-push-3 {
    left: 25%;
  }

  .col-xl-push-2 {
    left: 16.66666667%;
  }

  .col-xl-push-1 {
    left: 8.33333333%;
  }

  .col-xl-push-0 {
    left: auto;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }

  .visible-xl {
    display: block !important;
  }

  table.visible-xl {
    display: table;
  }

  tr.visible-xl {
    display: table-row !important;
  }

  th.visible-xl, td.visible-xl {
    display: table-cell !important;
  }

  .visible-xl-block {
    display: block !important;
  }

  .visible-xl-inline {
    display: inline !important;
  }

  .visible-xl-inline-block {
    display: inline-block !important;
  }

  .hidden-xl {
    display: none !important;
  }
}
