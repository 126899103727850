@import url(../node_modules/bootstrap/dist/css/bootstrap.css);
@import url(../node_modules/admin-lte/dist/css/AdminLTE.css);
@import url(../node_modules/admin-lte/dist/css/skins/skin-blue.css);

section.content {
    min-height: 1000px;
}

.product-recommendation-form-padding {
    padding-top: 70px;
}

@media (max-width: 800px) {
    .product-recommendation-form-padding {
        padding-top: 200px;
    }
}

@media (max-width: 500px) {
    .product-recommendation-form-padding {
        padding-top: 250px;
    }
}